<template>
    <div class="identity-pop">
        <el-dialog
            v-model="dialogVisible"
            width="50%"
            class="dialog-class"
            :close-on-click-modal="false"
            :show-close="false"
            style="border-radius: 4px"
        >
        <template #header="{ titleId, titleClass }">
                <div :id="titleId" :class="titleClass">
                    <div class="details-header bj-flex-between">
                        <div class="header-title">{{ title }}</div>
                    </div>
                </div>
            </template>
            <ul class="identity-info" style="overflow: auto">
                <li v-for="(item, index) in userIndentity" :key="index" class="infinite-item" @click="userTouch(item)" :class="{ 'item-active': merchantId == item.merchantId }">
                    <div class="info-name bj-flexitem-1 bj-flex-start">
                        <div class="bj-flexitem-1 bj-flex-start">
                            <img class="info-logo" src="../../assets/user-logo.png" />
                            <div class="merchant-name">
                                {{ item.companyType === 4 ? "广告商：" : item.companyType === 5 ? "餐饮商家：" : "" }}{{ item.merchantName }}
                            </div>
                            <!-- <span class="merchant-status" v-if="item.roleId < 0">
                                <el-tag type="danger">审核中</el-tag>
                            </span> -->
                            <span class="merchant-status" v-if="item.applyStatus&&item.applyStatus!==2" >
                                <el-tag type="danger" v-if="item.applyStatus==1">审核中</el-tag>
                                <el-tag type="danger" v-if="item.applyStatus==4">审核拒绝</el-tag>
                            </span>
                        </div>
                        <!-- :class="{'item-active' : soleId == item.userRoleId}" -->
                        <div>
                            <img class="radio-img" v-if="merchantId == item.merchantId" src="../../assets/radio-checked.png" />
                            <img v-else class="radio-img" src="../../assets/radio.png" />
                        </div>
                    </div>
                    <!-- <div class="info-role">
                        <div class="role-title">我的身份</div>
                        <el-tag
                            v-for="tag in item.roleName ? item.roleName.split(',') : []"
                            :key="tag"
                            style="margin: 8px 8px 0px 0px"
                            type="info"
                        >
                            {{ tag }}
                        </el-tag>
                    </div> -->
                </li>
            </ul>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="logout">取消</el-button>
                    <el-button type="primary" @click="userAdd">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import store from "@/store";

export default defineComponent({
    props: {
        userIndentity: {
            type: Array,
            required: true,
            default: function () {
                return []; 
            }
        },
        userInfo: {
            type: Object,
            required: true,
            default: function () {
                return {}; 
            }
        },
        logoutLogin: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    setup(props: any, context) {
        const dialogVisible = ref<any>(false);
        const indentInfo = ref<any>({});
        const merchantId = ref<any>(props.userInfo ? props.userInfo.merchantId || null : null);
        const title = ref<any>("身份切换");
        //身份选中事件
        const userTouch = (item) => {
            indentInfo.value = item;
            merchantId.value = item.merchantId;
        };
        //身份切换确定事件
        const userAdd = () => {
            if(JSON.stringify(indentInfo.value) == '{}'){
                dialogVisible.value = false;
                return;
            }
            context.emit("userAdd", indentInfo.value);
            dialogVisible.value = false;
        };
        const logout = () => {
            dialogVisible.value = false;
            if (props.logoutLogin) {
                store.dispatch("userAdvert/logout");
            }
        };
        return {
            dialogVisible,
            indentInfo,
            merchantId,
            title,
            userTouch,
            userAdd,
            logout,
        };
    },
});
</script>
<style lang="scss" scoped>
.identity-info {
    height: 400px;
    padding: 0;
    margin: 0;
    list-style: none;

    .infinite-item {
        margin: 10px;
        border: 1px solid #eeeff0;
        color: #2d2e2f;
        font-size: 16px;
    }
    .info-name {
        padding: 8px 12px;
        background: #f5f6f7;
    }
    .radio-img {
        width: 20px;
        height: 20px;
    }
    .info-logo {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 50%;
    }
    .merchant-name {
        position: relative;
        padding-right: 16px;
        color: #2d2e2f;
        font-size: 16px;
        font-weight: 500;
    }
    .merchant-status {
        position: relative;
        padding-left: 16px;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            width: 1px;
            height: 16px;
            transform: translate(0, -50%);
            background: #cacbcc;
            z-index: 1;
        }
    }
    .info-role {
        padding: 12px 4px 12px 12px;
    }
    .role-title {
        padding-bottom: 8px;
        color: #979899;
        font-size: 14px;
    }
    .item-active {
        border: 2px solid 4468EA;
    }
}
::v-deep .el-button--primary{
    background-color:#00796A !important;
}
::v-deep .el-button--primary:hover{
    background-color: #00897B !important;
}
::v-deep .el-button:hover{
    background-color: #00897B !important;
    color:#fff !important;
}
</style>