<template>
    <div class="scroll-container" ref="scrollContainer" @wheel.prevent="handleScroll">
        <div class="scroll-wrapper bj-flex-center" ref="scrollWrapper">
            <div class="left-roll bj-flex-center" @click="handleLeftClick">
                <el-icon><DArrowLeft /></el-icon>
            </div>
            <div class="bj-flexitem-1" ref="fatherSwiper">
                <div class="center-roll" ref="swiperScrollContent" :style="{ left: -leftNumber + 'px' }">
                    <slot></slot>
                </div>
            </div>
            <div class="right-roll bj-flex-center" @click="handleRightClick">
                <el-icon><DArrowRight /></el-icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
    defineComponent,
    ref,
    onMounted,
    reactive,
    watch,
    nextTick,
} from "vue";
import { DArrowLeft, DArrowRight } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
export default defineComponent({
    components: {
        DArrowLeft,
        DArrowRight,
    },
    name: "scrollPane",
    props: {
        visitedViews: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    setup(props: any, context) {
        const router = useRouter();
        // 点击左右箭头监听
        const clickNum = ref<Number>(0);
        const leftNumber = ref<any>(0);
        // 盒子宽度
        const swiperScrollWidth = ref<any>(0);
        // 父级盒子的ref
        const fatherSwiper = ref<any>(null);
        const fatherWidth = ref<any>(0);
        // 盒子内容组数转换为数组
        const childrenList = reactive<any>([]);
        // 中间盒子的ref
        const swiperScrollContent = ref<any>(null);
        // 点击右箭头（左侧滚动）
        const handleRightClick = () => {
            let rightDistance = childrenList.value[childrenList.value.length - 1].getBoundingClientRect().left;
            if (fatherWidth.value < rightDistance) {
                if (typeof leftNumber.value === "number" && typeof fatherWidth.value === "number") {
                    leftNumber.value = leftNumber.value + fatherWidth.value;
                }
            }
        };
        // 点击左箭头（右侧滚动）
        const handleLeftClick = () => {
            let leftDistance = childrenList.value[0].getBoundingClientRect().left;
            if (leftDistance < 0) {
                if (typeof leftNumber.value === "number" && typeof fatherWidth.value === "number") {
                    leftNumber.value = leftNumber.value - fatherWidth.value;
                }
            }
        };
        //监听路由变化定位到当前位置
        watch(() => router.currentRoute.value, (newValue: any) => {
			props.visitedViews.forEach((item, index) => {
				if (newValue.path == item.path) {
					nextTick(() => {
						let rightDistance = childrenList.value[index].getBoundingClientRect().left;
						if (fatherWidth.value < rightDistance) {
							leftNumber.value =
								leftNumber.value + fatherWidth.value;
						} else if (rightDistance < 0) {
							leftNumber.value = leftNumber.value - fatherWidth.value;
						}
					});
				}
			});
		},{ immediate: true });
        onMounted(() => {
            childrenList.value = swiperScrollContent.value.children;
            swiperScrollWidth.value = Math.ceil(swiperScrollContent.value.getBoundingClientRect().width);
            fatherWidth.value = Math.ceil(fatherSwiper.value.getBoundingClientRect().width);
        });
        return {
            clickNum,
            leftNumber,
            childrenList,
            fatherSwiper,
            swiperScrollWidth,
            swiperScrollContent,
            handleRightClick,
            handleLeftClick,
        };
    },
});
</script>

<style lang="scss" scoped>
.scroll-container {
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    height: 40px;
    width: 100%;
    .scroll-wrapper {
        width: 100%;
        height: 100%;
    }
    .center-roll {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        transition: all 0.3s;
    }
    .left-roll {
        height: 100%;
        padding-left: 10px;
        background: #fff;
        background: #fff;
        z-index: 1;
    }
    .right-roll {
        height: 100%;
        padding: 0px 10px;
        background: #fff;
        z-index: 1;
    }
}
</style>
