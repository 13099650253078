import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem.js'

import ElementPlus from 'element-plus';
import * as filter from "./utils/filter"
import 'element-plus/theme-chalk/index.css';
import './styles/common/base.css';
import './styles/index.scss';
import * as ElIcon from '@element-plus/icons-vue'
import https from './utils/http'
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { handleTree } from "./utils/sysRuoyi/ruoyi"

const app = createApp(App)

app.config.globalProperties.handleTree = handleTree
app.config.globalProperties.$filter = filter
Object.keys(ElIcon).forEach((key) => {
	app.component(key, ElIcon[key])
})
app.provide('global', {
	https
})
app.use(store).use(router).use(ElementPlus).mount('#app')

