<template>
<div class="view-box">
    <div>
        <left-nav class="let-nav"></left-nav>
    </div>
    <div class="content-box bj-flexitem-1">
        <top-nav class="top-nav"></top-nav>
        <tags-view></tags-view>
        <div class="main-box">
            <router-view class="main-content" :key="key"></router-view>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import topNav from './components/topNav.vue';
import leftNav from './components/leftNav.vue';
import tagsView from "./components/tagsView/tagsView.vue";
import { useRoute } from "vue-router";
import { watch, ref} from "vue";
export default {
    components: {
        leftNav,
        topNav,
        tagsView
    },
    data() {
        const route = useRoute();
        const key = ref<string>("/");
        watch(() => route.path,(newValue: any) => {
			key.value = newValue;
		},{ immediate: true });
        return {
            key
        }
    }
}
</script>

<style lang="scss" scoped>
.view-box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    
    .content-box {
        width: calc(100vw - 232px);
        flex: 1;
        // position: relative;
    }
    .main-box {
        padding: 16px;
        height: calc(100vh - 100px);
        overflow: auto;
        flex: 1;
        width: 100%;
        .main-content {
            // width: calc(100vw - 282px);
            min-height: calc(100vh - 136px);
        }

        .company-name {
            padding-top: 20px;
            color: #333;
            font-size: 12px;
            text-align: center;

        }
    }
}
</style>
