/**
 * @description 用户信息存储
 */
 import Cookies from 'js-cookie';

 const keys = {
    advertUser: 'ADVERT_USER',
    advertToken: 'ADVERT_TOKEN',
    advertAccount:'ADVERT_ACCOUNT',
    keyUserList: 'ADVERT_LIST'
}
const getStorage = function (key:any) {
    let value = localStorage.getItem(key)
    if (value) {
        return JSON.parse(value);
    }
    return value;
}

const setStorage = function (key:any, value:any) {
    if (value === null || value === undefined) {
        localStorage.removeItem(key)
        return;
    }
    value = JSON.stringify(value)
    localStorage.setItem(key, value)
}
const getToken = function (key:any) {
    return Cookies.get(key);
}
const setToken = function (key:any, value:any, days:any) {
    if (value !== null) {
		var inFifteenMinutes = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * days); //8小时过期
		Cookies.set(key, value, { expires: inFifteenMinutes });
    } else {
        Cookies.remove(key)
    }
}
const userMemory = function (key:any, value:any){
    if(value !== null){
        var inFifteenMinutes = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 7); //8小时过期
        Cookies.set(key, value, { expires: inFifteenMinutes });
    }else{
        Cookies.remove(key)
    }
}
export default Object.assign(keys, {
    getStorage,
    setStorage,
    getToken,
    setToken,
    userMemory
})