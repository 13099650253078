const getters: any = {
  // token
  token: state => state.userAdvert.token,
  // 用户信息
  advertInfo: state => state.userAdvert.advertInfo,
  menuList: state => state.permissionRepast.menuList,
  userList: state => state.permissionRepast.userList,
  pageList: state => state.pageInfo.pageList,
  pageInfo: state => state.pageInfo.pageInfo,
}
export default getters
