<template>
	<div class="app">
		<el-config-provider :locale="locale">
			<router-view />
		</el-config-provider>
	</div>
</template>

<script>
import { defineComponent,onMounted,provide } from "vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";

export default defineComponent({
	name: 'app',
	setup(){
		let locale = zhCn;
		onMounted(() => {
			document.body.style.setProperty("--el-color-primary", "#00796A");
			document.body.style.setProperty("--el-color-primary-light-2", "#00796A");
			document.body.style.setProperty("--el-color-primary-light-3", "#09A08E");
			document.body.style.setProperty("--el-color-primary-light-9", "#EBFFFC");
			document.body.style.setProperty("--el-color-primary-dark-2", "#00796A");
			document.body.style.setProperty("--el-color-primary-light-5", "#8DC9C1");
			document.body.style.setProperty("--el-color-primary-light-5", "#8DC9C1");
		})
		return{
			locale
		}
	}
})
</script>

<style scoped lang="scss">
.app{
	min-width:1360px;
	width: 100%;
	height: 100%;
}
</style>
