// 组件编辑信息存储
const state: any = {
    pageList:[],//页面合集
    pageInfo:{},//上次编辑的当前页面
}

const mutations = {
    SET_LIST: (state:any, data:any) => {
        state.pageList = data
    },
    CLEAR_LIST: (state:any, data:any) => {
        state.pageList = []
    },
    SET_INFO: (state:any, data:any) => {
        state.pageInfo = data
    },
    CLEAR_INFO: (state:any, data:any) => {
        state.pageInfo = {}
    }
}

const actions = {
    setStoreList(context: any, device: String) {
        context.commit('SET_LIST', device)
    },
    getStoreList(context: any, device: String) {
        context.commit('CLEAR_LIST', device)
    },
    setStoreInfo(context: any, device: String) {
        context.commit('SET_INFO', device)
    },
    getStoreInfo(context: any, device: String) {
        context.commit('CLEAR_INFO', device)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
