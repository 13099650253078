import { createStore } from 'vuex'
import getters from './getters'
import userAdvert from './modules/userAdvert'
import permissionRepast from './modules/permissionRepast'
import pageInfo from './modules/pageInfo'
import tagsView from './tagsView'

// 本地存储解决刷新数据小时问题
import createPersistedState from "vuex-persistedstate"

const store = createStore({
	modules: {
		userAdvert,
		pageInfo,
		permissionRepast,
		tagsView
	},
	getters,
	plugins: [
		createPersistedState({
			storage: window.localStorage,
			paths: ['userAdvert','permissionRepast','tagsView','pageInfo']
		})
	]
})
export default store;
