import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-74b03452"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tags-view" }
const _hoisted_2 = { class: "tags-view-item" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "contextmenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBold = _resolveComponent("CloseBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_scroll_pane = _resolveComponent("scroll-pane")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_scroll_pane, {
      class: "tags-view-wrapper",
      ref: "scrollPane",
      visitedViews: _ctx.visitedViews
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navTouch('/'))),
            class: _normalizeClass(["tags-item bj-flex-center", _ctx.isActive({ path: '/' }) ? 'active' : ''])
          }, " 首页 ", 2)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(_ctx.visitedViews), (tag) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "tags-view-item",
            onClick: ($event: any) => (_ctx.navbarTouch(tag)),
            ref_for: true,
            ref: "tagsItem",
            key: tag.path
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["tags-item bj-flex-center", _ctx.isActive(tag) ? 'active' : ''])
            }, [
              _createTextVNode(_toDisplayString(tag.name) + " ", 1),
              _createVNode(_component_el_icon, {
                class: "tags-icon",
                onClick: _withModifiers(($event: any) => (_ctx.closeSelectedTag(tag)), ["prevent","stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CloseBold)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ], 2)
          ], 8, _hoisted_3))
        }), 128))
      ]),
      _: 1
    }, 8, ["visitedViews"]),
    _createElementVNode("div", {
      class: "right-btn",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changeLabel && _ctx.changeLabel(...args)), ["stop"]))
    }, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_ArrowDown)
        ]),
        _: 1
      })
    ]),
    _withDirectives(_createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeSelectedTag(_ctx.selectedTag.value)))
      }, "关闭当前页"),
      _createElementVNode("li", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeOthers && _ctx.closeOthers(...args)))
      }, "关闭其他页"),
      _createElementVNode("li", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeAll && _ctx.closeAll(...args)))
      }, "关闭全部")
    ], 512), [
      [_vShow, _ctx.visible]
    ])
  ]))
}